@import '../../../../../styles/variables';
@import '../../../../../styles/colors.module.scss';

.newsBlockItem {
  display: flex;
  flex-direction: row;
  width: 460px;
  max-width: 460px;
  height: 110px;
  background-color: $colorWhite;
  margin-bottom: 12px;
  border-radius: 6px;
}

.newsBlockItemImageWrapper {
  width: 140px;
  height: 110px;

  img {
    border-radius: 6px 0px 0px 6px;
    width: auto;
    height: 100%;
    object-fit: cover;
    display: flex;
  }
}

.newsBlockItem:hover {
  cursor: pointer !important;
  // box-shadow: 1px 1px 6px #707070;
}

.newsBlockItem:hover .newsBlockItemTitle,
.newsBlockItem:hover .newsBlockItemTitleNight {
  color: $colorDarkBlue;
}

.newsBlockItemDate,
.newsBlockItemDateNight {
  color: $colorGrey50;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

.newsBlockItemDateNight {
  color: $colorMainNight;
}

.newsBlockItemInfoWrapper {
  padding: 7px 14px;
  width: 100%;
  max-width: 320px;
  overflow: hidden;
  // padding: 12px;
}

.newsBlockItemTitle,
.newsBlockItemTitleNight {
  color: $colorBlack;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  margin-top: 8px;
  overflow: hidden;
}

.newsBlockItemTitleNight {
  color: $colorWhite;
}

@media screen and (max-width: $minWidthSm) {
  .newsBlockItem {
    height: auto;
    width: 100%;
  }
}
