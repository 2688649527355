@import '../../../../../styles/variables';
@import '../../../../../styles/colors.module.scss';

.bigNewsBlockItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 476px;
  max-width: 460px;
  background-color: $colorWhite;
  margin-bottom: 20px;
  border-radius: 6px;
}

.bigNewsBlockItemImageWrapper {
  width: 100%;
  max-width: 460px;
  // width: 460px;
  // height: 260px;

  img {
    border-radius: 6px 6px 0px 0px;
    width: 100%;
    object-fit: cover;
    display: flex;
  }
}

.bigNewsBlockItem:hover {
  cursor: pointer !important;
  // box-shadow: 1px 1px 6px #707070;
}

.bigNewsBlockItem:hover .bigNewsBlockItemTitle,
.bigNewsBlockItem:hover .bigNewsBlockItemTitleNight {
  color: $colorDarkBlue;
}

.bigNewsBlockItem:hover .bigNewsBlockItemSubtitle,
.bigNewsBlockItem:hover .bigNewsBlockItemSubtitleNight {
  color: $colorBlack;
}

.bigNewsBlockItemInfo {
  padding: 7px 14px;
}

.bigNewsBlockItemDate,
.bigNewsBlockItemDateNight {
  color: $colorGrey50;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

.bigNewsBlockItemDateNight {
  color: $colorMainNight;
}

.bigNewsBlockItemTitle,
.bigNewsBlockItemTitleNight {
  color: $colorBlack;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  margin-top: 20px;
  overflow: hidden;
}

.bigNewsBlockItemSubtitle,
.bigNewsBlockItemSubtitleNight {
  color: $colorGrey50;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 10px;
}

.bigNewsBlockItemTitleNight,
.bigNewsBlockItemSubtitleNight {
  color: $colorWhite;
}

@media screen and (max-width: $minWidthSm) {
  .bigNewsBlockItem {
    height: auto;
  }
}
