@import '../../../styles/colors.module.scss';
@import '../../../styles/variables';

.publicationsWrapper {
  padding-top: 40px;
  background-color: $colorGrey;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.circularProgressWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.advertisementsBlock {
  margin-left: 20px;
  width: auto;
  height: 100%;
}

.testAdvertisementBlock {
  display: block;
  width: 300px;
  background-color: aqua;
  height: 600px;
}

.advertisementsLowerBlock {
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 10px;
  justify-content: center;
}

.additionalOperations {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 90px;
}

.downloadMoreBtn {
  display: flex;
  width: 320px;
  height: 40px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 10px;
  flex-shrink: 0;
  color: $colorLightGrey;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  border-radius: 6px;
  background-color: $colorDarkBlue;
  border: 1px solid $colorDarkBlue;

  &:hover {
    cursor: pointer;
    // box-shadow: 1px 1px 6px #707070;
    background-color: $colorWhite;
    color: $colorDarkBlue;
  }
}

@media screen and (min-width: $maxWidthMd) {
  .advertisementsBlock {
    display: flex;
  }
  .advertisementsLowerBlock {
    display: none;
  }
}

@media screen and (max-width: $maxWidthMd) {
  .advertisementsBlock {
    display: none;
  }
  .advertisementsLowerBlock {
    display: flex;
  }

  .publicationsCentralBlock {
    width: 100%;
  }

  .downloadMoreBtn {
    max-width: 320px;
    width: calc(100% - 40px);
  }
}
