@import '../../../../styles/variables';
@import '../../../../styles/colors.module.scss';

.newsBlock,
.newsBlockNight {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 0px 20px;
}

.newsBlockNight {
  background-color: $colorBlack;
}

.bigNewsWrapper,
.newsWrapper {
  display: flex;
  flex-direction: column;
}

.newsWrapper {
  margin-left: 20px;
}

.newsWrapper :nth-child(4n) {
  margin-bottom: 20px;
}

@media screen and (max-width: $minWidthMd) {
  .newsBlock,
  .newsBlockNight {
    flex-direction: row;
  }
}

@media screen and (max-width: $maxWidthSm) {
  .newsBlock,
  .newsBlockNight {
    flex-direction: column;
    // align-items: center;
  }

  .newsWrapper {
    margin-left: 0px;
  }
}
